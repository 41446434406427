body {
    background-color: rgba(30,30,53,255);
    background-image: url('./bg-texture.png');
    color: white;
}
html, body {
    max-width: 100%;
    overflow: hidden;
}
.main-cont {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} .fade-text {
    opacity: .5;
} .main-btns {
    margin-top: 2em;
} .main-btns >.btnA button, .btn-link >button {
    border-radius: 5px;
    padding: .5em 1em;
    background-color:rgba(98,148,185,255);
    border: none;
    transition: all .35s ease-in-out;
} .main-btns > .btnA button:nth-child(1){
    color: rgba(98,148,185,255);
    background-color: rgba(0, 0, 0, 0);
    margin-right: 1em;
    border: 1px solid rgba(98,148,185,255);
}.main-btns > .btnA button:hover , .btn-link > button:hover {
    background-color: white;
} .contact {
    color:rgba(98,148,185,255);
} .sub-cont {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 5em;
    justify-content: space-around;
    align-items: center;
} .sub-cont > div , .sub-cont form{
    display: flex;
    flex-direction: column;
    gap: 1em;

} .contacts {
    cursor: pointer;
    height: 11em;
    width: 15em;
    background-color:rgba(41,43,102,255);
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: background-color .5s ease-in-out, border .5s ease-in-out;
    gap: .5em;
    align-items: center;
} .contacts > span {
    font-size: 10pt;
    color: rgba(98,148,185,255);
} .contacts:hover {
    background-color: rgba(41,43,102,0);
    border: 1px solid rgba(255, 255, 255, 0.5);

}.sub-cont > div:nth-child(1) {
    width: 35%;
    align-items: flex-end;
    padding-right: 1em;
}.sub-cont > div:nth-child(2) {
    height: 100%;
    flex-grow: 1;
    padding-left: 5%;
} form {
    flex-grow: 1;

} .sub-cont input, textarea{
    width: 45%;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(98,148,185,255);
    padding: 1em;
    border-radius: 10px;
    color:white;
} .sub-cont input:nth-child(1) {
    margin-top: -25px;
} .sub-cont button {
    width: 15%;
    padding: .6em;
    border: none;
    border-radius: 10px;
    background-color:rgba(98,148,185,255);
} .in-ani, .c-ani {
    opacity: 0;
}


.top-cont {
    position: relative;
} .scroller > a {
    z-index: 25;
    text-decoration: none;
    position: absolute;
    color: rgba(98,148,185,255);
    bottom: 5%;
    right: 10%;
    transform: rotate(90deg);
}.b-media {
    position: absolute;
    z-index: 25;
    left: 10%;
    bottom: 2%;
} .space-cont {
    width: 100%;
    height: 50%;
} .img-cont {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50%;
} .img-cont > span {
    height: 100%;
    display: block;
    background-color:rgba(98,148,185,255);
    width: 25em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;
    border-radius: 50% 50% 0 0;
    overflow: hidden;
    position: relative;

} .img-cont > span >img {
    height: 112%;
    margin-bottom: -3.5em;
    width: auto;
    position: absolute;
    top: 0;
} .abt-cont {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
}.acnt {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}.abt-left {
    position:relative;
    justify-content: flex-end;
}.front-img{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    width: 20em;
    height: 20em;
    border-radius:10px;
    transform: rotate(8deg);
    overflow: hidden;

}.back-img {
    position: absolute;
    z-index: 10;
    border-radius:10px;
    width: 20em;
    height: 20em;
    background-color: #47a6e0;
}.abt-left img {
    width:auto;
    height: 100%;
} .abt-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1em;
} .abt-right > .right-top, .abt-right > .right-bot {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
} .right-bot > span {
    width: 45em;
    text-align: justify;
} .about {
    min-height: 100vh;
    padding-top: 3.5em;
}.acnt-sk {
    /* border: 1px solid white; */
    flex-grow: 1;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
} .acnt-sk > span {
    cursor: pointer;
    position: absolute;
    width: 40vw;
    font-size: 10pt;
    background-color: rgba(41,43,102,255);
    color: rgba(98,148,185,255);
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 10px;
    transition: all .5s ease-in-out;
    padding: 2.5em 5em;

} .acnt-sk > span:hover {
    background-color: rgba(41,43,102,0);
    border: 1px solid rgba(255, 255, 255, 0.5);
}.acnt-sk h5.head-sk {
    text-align: center;
} .front-end{
    right: 2em;
    width: 100%;
} .exp {
    padding-top: 2em;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1em;
} .exp > span {
    flex-grow: 1;
    width: 40%;
} .sub-head-sk {
    color:white;
} .sv {
    display: flex;
    justify-content: space-around;
    padding: 0 7em;
} .sv > div {
    border: 1px solid #4bb5f5;
    width: 25%;
    border-radius: 0 0 35px 35px;
    background-color: rgba(41,43,102,1);
    transition: all .5s ease-in-out;
    cursor: pointer;
    height: fit-content;
} .sv > div:hover {
    background-color: rgba(41,43,102,0);
}.sv-head {
    background-color: #4bb5f5;
    color: rgba(41,43,102,1);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 5em;
    border-radius: 0 0 35px 35px;

} .sv > div > div {
    padding: 1em 2em;
    color: white;
    display: flex;
    align-items: center;
}.nav-cont {
    width: 100%;
    height: 5em;
    position: fixed;
    bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}.fix-nav {
    cursor: pointer;
    /* position: fixed;
    bottom: 1em;
    left: 43.5%; */
    background-color: #0f101c;
    display: flex;
    border-radius: 50px;
    padding: .5em 1em;
    gap: .5em;
    z-index: 25;

} .fix-nav > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em;
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    background-color: rgba(23,23,41,0);
    transition: all .4s ease-in-out;

} .fix-nav > span:hover {
    background-color: rgba(23,23,41,255);
} .fix-nav a {
    color: white;
} .mobile {
    display: none;
} .mobile  > div {
    display: flex;
    flex-direction: column;
} .mobile .front-img img {
    height: 100%;
} .mobile .pp-layer {
    height: 25em;
    display: flex;
    justify-content: center;
    align-items: center;
} .mobile .abt-layer p {
    text-align: justify;
    padding: .5em;
    margin-bottom: -1em;
    
} .skill-mob {
    width: 100%;
    padding-top: 5em;
} .skill-mob > div {
    padding-left: 1em;
    width: 100%;
} .skill-mob h5.head-sk {
    text-align: center;
}.sv-mob {
    padding: 0 .5em;
} .sv-mob > div > div{
    border: 1px solid #4bb5f5;
            width: 95vw;
            border-radius: 0 0 35px 35px;
            background-color: rgba(41,43,102,1);
            transition: all .5s ease-in-out;
            cursor: pointer;
            height: fit-content;
}.sv-mob > div > div > div > div {
    padding: 1em 2em;
    color: white;
    display: flex;
    align-items: center;
} .m-media {
    display: none;
}

/* about responsive */


@media only screen and (max-width: 1440px) {
    .abt-desk-cont {
        flex-direction: column;
        align-items: center;
    } .abt-desk-cont > .abt-left {
        height: 22em;
        width: 22em;
    }
}

/* ================ */


/* Mobile css */
@media only screen and (max-width: 1000px) {
        .main-cont{
            min-height: 100vh;
        }
        /* .fix-nav  {
            position: fixed;
            left: 15%;
            top: 92vh;
            bottom:unset;
        } */
        .scroller {
            display: none;
        } .b-media {
            display: none;
        }
         .desktop {
            display: none;
        } .mobile {
            display: flex;
        }.mobile .sv {
            display: block;
            padding: 0;
        }.mobile .sv > div {
            border: 1px solid #4bb5f5;
            width: 95vw;
            border-radius: 0 0 35px 35px;
            background-color: rgba(41,43,102,1);
            transition: all .5s ease-in-out;
            cursor: pointer;
            height: fit-content;
        } .sub-cont {
            flex-direction: column;
        } .sub-cont > div:nth-child(1) {
            display: none;
        }.sub-cont > div:nth-child(2) {
            height: 100%;
            flex-grow: 1;
            padding-left: 5%;
            width: 100%;
        }.sub-cont > div:nth-child(2) input , .sub-cont > div:nth-child(2) textarea {
            width: 90%;
        }.sub-cont > div:nth-child(2) button {
            width: fit-content;
        }
        .m-media {
            display: block;
        }
}


/* work comp */
.work-cont {
    text-align: center;
    display: flex;
    /* flex-shrink: 1; */
    flex-wrap: wrap;
    justify-content: center;
}.work-cont a {
    margin-top: 1em;
}
.work-cont img {
    height: 90px;
    width: auto;
    margin: 1em;
} .custom-skill {
    padding-top: 0;
} .custom-skill > div {
    min-height: 30%;
}

@media only screen and (max-width: 1665px) {
    #serv, #exp, #about, #contact {
        min-height: 100vh;
    } 
}

@media only screen and (max-height: 658px) {
    .custom-service {
        min-height: 150vh;
    }
} 

@media only screen and (max-height: 825px) {
    .main-cont {
        margin-bottom: 25em;
    }
}
@media only screen and (max-height: 380px) {
    .main-cont {
        margin-bottom: 35em;
    }
}