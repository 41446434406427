.welcome-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    color: white;
    z-index: 900;
    background-color: #1e1e35;
    display: flex;
    justify-content: center;
    align-items: center;
} .welcome-screen > div {
    color:green;
    font-size: 70pt;
    margin-bottom: 2em;
} .txt-block {
    display: block;
    background-color: #1e1e35;
    width: 100%;
    height: 2em;
    position: absolute;
    top: 54.5%;
    left: 0;
}.txt-appear {
    visibility: hidden;
    display: inline-block;
} .welcome-wipe {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #6294b9;
    bottom:-150%;
    right:0;
    z-index: 950;
} .leftSpace {
    display: inline-block;
    padding-left: 3em !important;
}

@media only screen and (max-width: 1024px) {
    .welcome-screen > div {
        font-size: 20pt;
    }.txt-block {
        position: absolute;
        top: 51.5%;
    } 
    @media only screen and (max-width: 1180px) {
        .txt-block {
            position: absolute;
            top: 50.9%;
        } 
        @media only screen and (max-width: 925px) {
            .txt-block {
                position: absolute;
                top: 51.8%;
            } 
            /* responsiveness for shitty foldable */
            @media only screen and (max-width: 700px) {
                .welcome-screen > div {
                    font-size: 15pt;
                }.txt-block {
                    position: absolute;
                    top: 50.3%;
                } 
            }
        }
    }
}