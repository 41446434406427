
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* width */
body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #1e1e35;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #6294b9;
  transition: all .5s ease-in-out;

}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: rgba(98,148,185,.5);
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: fit-content !important;
  padding: 2em 0;
  margin-bottom: 12em;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.m-media {
  margin-top: 5px;
  margin-bottom: -15px;
}
.m-media a {
  margin-left: .5em;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dashboard {
  display: flex;
} main {
  flex-grow: 1;
} .sideNav {
  min-width:12vw;
  height:94.2vh;
  background-color:black;
} .store-form {
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  width: 80vw;
} .store-form div  input {
  width: 40em;
  margin-right: 1em;
  padding: 5px;
  border: .5px solid rgba(0,0,0,0.2);
} .store-form .sub-form {
  display: flex;
} .store-form .sub-form > div {
  display: flex;
  flex-direction: column;
} .store-form .sub-form > div.col-fill input {
  width: 69vw;
} .store-footer {
  position: absolute;
  bottom: 15px;
  right: 15em;
}